import {ArrowUpIcon, ArrowUturnDownIcon, ArrowsRightLeftIcon, ChartPieIcon } from "@heroicons/react/24/outline";
import { BoltIcon } from "@heroicons/react/24/solid";
import {  ArrowUpCircleIcon, GiftIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";
import Tabs from "../../layout/tabs";
import Header from "../../layout/header";
import moment from "moment";

import "chart.js/auto"
import React, { useState, useEffect, useContext, Fragment } from "react";
import { AccountBreakdown } from "../../api";
import { AccountValuePlaceholder } from "../../component";
import { AccountBreakdownCardPlaceholder, AccountRequestsPlaceholder } from "../../component/placceholder";
import Bitcoin from "../../asset/bitcoin";
import MalaysianRinggit from "../../asset/malaysian-ringgit";
import { AccountRequest, IPortfolioContext, PortfolioContext } from "./context";
import { PersonaContext } from "../login/context";
import { ProfileContext } from "../settings/context";
import { FundListContext } from "../fund/context";
import Ethereum from "../../asset/ethereum";
import Titans from "../../asset/titans";
import HSRIFLogo from "../../asset/hsrif-logo";
import HSETHFLogo from "../../asset/hsethf-logo";
import HSCTFLogo from "../../asset/hsctf-logo";
import HSBTCFLogo from "../../asset/hsbtcf-logo";
import HSEIFLogo from "../../asset/hseif-logo";


export default function List() {
    const navigate = useNavigate()
    
    const portfolio = useContext(PortfolioContext)
    const profile = useContext(ProfileContext)
    const fundList = useContext(FundListContext)
    const persona = useContext(PersonaContext)
    const [state, setState] = useState<IPortfolioContext>(portfolio)

    useEffect(() => {
        if (!persona.authenticated) {
            navigate("/auth/email")
            return
        }
        setState(portfolio)
    }, [ portfolio.accountRequestsLoading, portfolio.accountValueLoading, portfolio.breakdownLoading, portfolio.switchinValueLoading  ])
    
    interface BreakdownProps {
        index: number
        value: AccountBreakdown
    }
    const BreakdownCard = ({ index, value }: BreakdownProps) => {
        // style={{width: 240, height: 180}}
        return <div key={value.fundCode} className="inline-block mr-5 bg-gray-50 hover:bg-gray-100 rounded-xl cursor-pointer w-72 h-48 border border-blue-100 whitespace-normal" onClick={() => navigate(`/portfolio/allocation/${value.fundId}/${value.fundClassSequence}`)}>
            <div className="p-3 h-full">
                <div className="flex flex-col justify-between h-full">
                    <div className="flex flex-col">
                        <div className="flex gap-1">
                            {/* TODO: what's for private mandates? */}
                            {value.fundCode === "hsrif" && <HSRIFLogo className="w-10 h-10" />}
                            {value.fundCode === "hsethf" && <HSETHFLogo className="w-10 h-10" />}
                            {value.fundCode === "hsctf" && <HSCTFLogo className="w-10 h-10" />}
                            {value.fundCode === "hsbtcf" && <HSBTCFLogo className="w-10 h-10" />}
                            {value.fundCode === "hseif" && <HSEIFLogo className="w-10 h-10" />}
                            <div className="flex flex-col">
                                <span className="text-lg font-medium text-black">{value.shortName} Fund</span>
                                <label className="text-xs font-medium text-gray-700 cursor-pointer">Class {value.fundClassLabel}</label>
                            </div>
                        </div>
                    </div>
                    
                    <div className="flex flex-col">
                        <span className="text-xl text-black font-medium">{value.value?.toLocaleString("en-my", { style: "currency", currency: value.quoteAsset, minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                        <div className="flex">
                            {value.pnlAmount === 0 && <span className={`text-sm mt-auto mb-auto text-green-600 font-medium`}>{value.pnlAmount?.toLocaleString("en-my", { style: "currency", currency: value.quoteAsset, minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>}
                            {value.pnlAmount > 0 && <span className={`text-sm mt-auto mb-auto text-green-600 font-medium`}>+{value.pnlAmount?.toLocaleString("en-my", { style: "currency", currency: value.quoteAsset, minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>}
                            {value.pnlAmount < 0 && <span className={`text-sm mt-auto mb-auto text-red-600 font-medium`}>{value.pnlAmount?.toLocaleString("en-my", { style: "currency", currency: value.quoteAsset, minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>}
                            
                            {value.pnlPercentage > 0 && <span className={`text-sm p-1 font-medium text-green-600`}>(+{value.pnlPercentage?.toLocaleString("en-my", {minimumFractionDigits:2, maximumFractionDigits: 2})}%)</span>}
                            {value.pnlPercentage === 0 && <span className={`text-sm p-1 font-medium text-green-600`}>({value.pnlPercentage?.toLocaleString("en-my", {minimumFractionDigits:2, maximumFractionDigits: 2})}%)</span>}
                            {value.pnlPercentage < 0 && <span className={`text-sm p-1 font-medium text-red-600`}>({value.pnlPercentage?.toLocaleString("en-my", {minimumFractionDigits:2, maximumFractionDigits: 2})}%)</span>}
                        </div>
                        <div className="flex gap-1">
                            <ChartPieIcon className="w-4 h-4 text-gray-700"/>
                            <label className="text-xs font-medium text-gray-700 cursor-pointer">{value.allocationPercentage.toLocaleString("en-my", { minimumFractionDigits: 2, maximumFractionDigits: 2})}% of Portfolio</label>
                        </div>
                    </div>

                    
                </div>

            </div>
            {/* <div className="absolute right-0 bottom-0" style={{ width: 240, height: 80 }}>
                X
            </div> */}
        </div>
    }

    const handleInvest = () => {
        navigate("/invest")
    }
    const handleSwitch = () => {
        if (!state.accountValue || state.accountValue?.portfolioValue <= 0 || fundList.privateMandates) {
            return
        }
        navigate("/switch")
    }
    const handleRedeem = () => {
        if (!state.accountValue || state.accountValue?.portfolioValue <= 0) {
            return
        }
        navigate("/redeem")
    }
    const handleDeposit = () => {
        navigate("/deposit")
    }
    const handleWithdraw = () => {
        navigate("/withdraw")
    }
    const handleCompleteProfile = () => {
        navigate("/settings/profile/wizard")
    }

    const renderRequestType = (r: AccountRequest) => {
        if (r.switchId && r.type === "purchase") {
            return "switch in"
        }
        if (r.switchId && r.type === "redeem") {
            return "switch out"
        }
        if (r.rebateId && r.type === "purchase") {
            return "rebate"
        }
        if (r.type === "purchase") {
            return "subscription"
        }
        if (r.type === "redeem") {
            return "redemption"
        }
        return ""
    }

    const renderFundIcon = (fundCode: string) => {
        if (fundCode === "hsbtcf") {
            return <Bitcoin className="w-6 -rotate-12" color="white" />
        }
        if (fundCode === "hsethf") {
            return <Ethereum className="w-6" color="white" />
        }
        if (fundCode === "hsrif") {
            return <MalaysianRinggit className="w-6" color="white" />
        }
        if (fundCode === "hsctf") {
            return <Titans className="w-6" color="white" />
        }
        return <BoltIcon className="w-6 -rotate-12" color="white" />
    }

    let lastRequestDate = ""

    return <div className="ml-3 pt-3 pb-24 md:mr-auto md:ml-auto" >
        <Header />
        <div className="mb-5">
            {(state.accountValueLoading || fundList.loading) && <AccountValuePlaceholder />}
            {(!state.accountValueLoading && !fundList.loading) && <div className="mt-5 mr-3 rounded-xl" style={{background: "linear-gradient(to top right, #004BC3, 70%, #116cff)"}}>
                <div className="pl-3 pt-3">
                    <span className="block text-blue-100">Portfolio value</span>
                    {state.accountExperience === "fundmanagement" && <span className="block text-2xl text-white font-medium">{state.accountValue?.portfolioValue.toLocaleString("en-my", { style: "currency", currency: state.accountValue?.quoteAsset, maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span>}
                    {state.accountExperience === "portfolio" && <span className="block text-2xl text-white font-medium uppercase">{fundList.amount?.toLocaleString("en-my", { style: "currency", currency: fundList.quoteAsset, maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span>}
                </div>
                {!state.switchinValueLoading && state.switchinValue > 0 && <div className="flex gap-1 pl-3 text-sm">
                    <span className="my-auto block text-white">Switching in</span>
                    <span className="block text-white font-medium">{state.switchinValue?.toLocaleString("en-my", { style: "currency", currency: "myr", maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span>
                </div>}
                <div className="pl-3 pt-1">
                    {state.accountExperience === "fundmanagement" && <span className="text-sm font-medium text-white">{state.accountValue?.pnlAmount > 0 && "+"}{state.accountValue?.pnlAmount.toLocaleString("en-my", {style: "currency", currency: state.accountValue?.quoteAsset, maximumFractionDigits: 2, minimumFractionDigits: 2})} ({state.accountValue.pnlPercentage?.toLocaleString("en-my", {maximumFractionDigits: 2, minimumFractionDigits: 2})}%)</span>}
                    <span className="text-xs text-white block">Since {moment(state.accountValue?.since).format("DD MMM YYYY")}</span>
                </div>
                <div className="pt-5 pb-3 pr-3">
                    <span className="block text-xs font-medium text-white text-right">{state.accountValue?.accountName} - {state.accountValue?.accountId}</span>
                </div>
            </div>}
            
            {!portfolio.accountRequestsLoading && portfolio.accountExperience === "fundmanagement" && <ul className={`mt-5 text-white flex text-center`}>
                {persona.canInvest && <li className="w-16 text-white p-1 text-sm cursor-pointer mr-auto ml-auto" onClick={handleInvest}>
                    <ArrowUpIcon className="block hg-bg-blue rounded-3xl p-2 w-8 h-8 mr-auto ml-auto stroke-2"/>
                    <span className="text-xs hg-text-blue">Invest</span>
                </li>}
                {persona.canSwitch && <li className="w-16 text-white p-1 text-sm cursor-pointer mr-auto ml-auto" onClick={handleSwitch}>
                    <ArrowsRightLeftIcon className={`block rounded-3xl p-2 w-8 h-8 mr-auto ml-auto stroke-2 ${!state.accountValue || state.accountValue?.portfolioValue <= 0 || fundList.privateMandates ? "bg-blue-300": "hg-bg-blue"}`}/>
                    <span className="text-xs hg-text-blue">Switch</span>
                </li>}
                {persona.canRedeem && <li className="w-16 text-white p-1 text-sm cursor-pointer mr-auto ml-auto" onClick={handleRedeem}>
                    <ArrowUturnDownIcon className={`block rounded-3xl p-2 w-8 h-8 mr-auto ml-auto stroke-2 ${!state.accountValue || state.accountValue?.portfolioValue <= 0 ? "bg-blue-300": "hg-bg-blue"}`}/>
                    <span className="text-xs hg-text-blue">Redeem</span>
                </li>}
            </ul>}
            
            {/* deposit */}
            {/* {portfolio.accountExperience === "portfolio" && <ul className={`mt-5 text-white flex text-center`}>
                {persona.canInvest && <li className="w-16 text-white p-1 text-sm cursor-pointer mr-auto ml-auto" onClick={handleDeposit}>
                    <ArrowUpIcon className="block hg-bg-blue rounded-3xl p-2 w-8 h-8 mr-auto ml-auto stroke-2"/>
                    <span className="text-xs hg-text-blue">Deposit</span>
                </li>}
                {persona.canRedeem && <li className="w-16 text-white p-1 text-sm cursor-pointer mr-auto ml-auto" onClick={handleWithdraw}>
                    <ArrowUturnDownIcon className={`block rounded-3xl p-2 w-8 h-8 mr-auto ml-auto stroke-2 ${!state.accountValue || state.accountValue?.portfolioValue <= 0 || fundList.privateMandates ? "bg-blue-300": "hg-bg-blue"}`}/>
                    <span className="text-xs hg-text-blue">Withdraw</span>
                </li>}
            </ul>} */}
            
            {/* only persona with investment role can complete the profile */}
            {persona.canInvest && profile.client.incompleteProfile && <div onClick={handleCompleteProfile} className="my-5 p-3 bg-yellow-100 border border-yellow-700 rounded-xl text-yellow-700 text-sm font-medium cursor-pointer">
                Please complete your profile
            </div>} 

            <div className="mt-5">
                <h1 className="text-md font-medium mb-3 text-blue-950">Allocations</h1>
                {state.breakdownLoading && <div className="">
                    <AccountBreakdownCardPlaceholder />
                </div>}
                {state.accountExperience === "fundmanagement" && !state.breakdownLoading && <div className="mr-3">
                    {state.breakdown && state.breakdown.length === 0 && <div className="text-center text-gray-700 border border-dashed p-6">
                        <p className="text-sm font-medium">Start investing today!</p>
                        {persona.canInvest && <button onClick={handleInvest} className="hg-bg-blue p-2 mt-2 px-6 text-white text-sm border rounded-full">
                            Invest
                        </button>}
                    </div>}
                </div>}
                {state.accountExperience === "fundmanagement" && !state.breakdownLoading && state.breakdown && state.breakdown.length > 0 && <Fragment>
                    <div className="overflow-auto text-white whitespace-nowrap 2xs:m-0 md:mr-3">
                        {state.breakdown?.sort((a, b) => a.allocationPercentage > b.allocationPercentage ? -1 : 1).map((b: AccountBreakdown, idx) => <BreakdownCard key={b.fundCode} index={idx} value={b}/> )}
                    </div>
                </Fragment>}
            </div>
            
            {!fundList.loading && fundList.privateMandates && <React.Fragment>
                <div className="w-full pr-3 mt-5">
                    <table className="w-full text-black">
                        <thead>
                            <tr className="bg-blue-50 text-blue-800 font-medium text-sm text-center h-8">
                                <td>Asset</td>
                                <td>Units</td>
                                <td>Value</td>
                                <td>Allocation %</td>
                            </tr>
                        </thead>
                        <tbody>
                            {fundList.holdings?.map(h => <tr className="text-xs text-center border-b border-b-blue-100 h-8">
                                <td className="uppercase">{h.asset}</td>
                                <td>{h.units.toLocaleString("en-my", { minimumFractionDigits: 6, maximumFractionDigits: 6 })}</td>
                                <td>{h.amount.toLocaleString("en-my", { style: "currency", currency: fundList.quoteAsset, minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                <td>{h.percentage.toLocaleString("en-my", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%</td>
                            </tr>)}
                        </tbody>
                    </table>
                </div>
            </React.Fragment>
            }
            
            {/* Hide halogen funds */}
            {/* <h1 className="mt-5 mb-3 text-md font-medium text-blue-950">Halogen funds</h1>
            <ul className="mt-5 text-white flex flex-wrap justify-around gap-5 text-center">
                {fundList.loading && <li className=" animate-pulse border text-white text-sm cursor-pointer w-72 hg-bg-light-blue rounded-lg">
                    <span className="block hg-bg-blue w-9 h-8 p-1 font-medium rounded-br-3xl rounded-tl-lg">
                        <Loader classNames="w-5 h-5" />
                    </span>
                    <span className="block text-sm pt-3 pb-8 pr-5 pl-5 hg-text-blue font-medium">
                        ...
                        <span className="block mt-1 text-xs text-blue-400">...</span>
                    </span>
                </li>
                }
                {!fundList.loading && fundList.funds?.map(f => {
                    return <li key={f.id} className="border text-white text-sm cursor-pointer w-72 hg-bg-light-blue rounded-lg" onClick={() => navigate(`/fund/${f.code}`)}>
                        <span className="block hg-bg-blue w-9 h-8 p-1 font-medium rounded-br-3xl rounded-tl-lg">
                            {renderFundIcon(f.code)}
                        </span>
                        <span className="block text-sm pt-3 pb-8 pr-5 pl-5 hg-text-blue font-medium">
                            {f.name}
                            <span className="block mt-1 text-xs text-blue-400">{f.tagline}</span>
                        </span>
                    </li>
                })}
            </ul> */}

            <h1 className="text-md font-medium mt-5 mb-3 text-blue-950">Transactions</h1>
            {state.accountRequestsLoading && <div className="pb-5 mr-3">
                <AccountRequestsPlaceholder />
            </div>}
            {/* Fund management experience (subscription / redemeptions) */}
            {!state.accountRequestsLoading && state.accountExperience === "fundmanagement" && <div className="pb-5 mr-3">
                {state.accountRequests && state.accountRequests.length === 0 && <div className="text-center text-gray-700 border border-dashed p-6">
                    <p className="text-sm font-medium">No requests made yet</p>
                </div>}
                {state.accountRequests && state.accountRequests.length > 0 && state.accountRequests.map((r) => {
                    const date = moment(r.createdAt).format("Do MMMM")
                    const item =  <div key={r.id}>
                        {date !== lastRequestDate && <span className=" bg-gray-50 mb-1 block text-sm rounded-sm text-gray-800 border p-2 font-medium capitalize">{date}</span>}
                        <div className="flex justify-between py-2 pb-3 cursor-pointer" onClick={() => navigate(`/portfolio/request/${r.id}`)}>
                            {!r.switchId && r.type === "purchase"  && !r.rebateId && <div><ArrowUpCircleIcon className="w-6 h-6 hg-text-blue"/></div>}
                            {!r.switchId && r.type === "purchase" && r.rebateId && <div><GiftIcon className="w-5 h-5 text-white hg-bg-blue rounded-3xl p-1 mt-0.5"/></div>}
                            {!r.switchId && r.type === "redeem" && !r.switchId && <div><ArrowUturnDownIcon className="text-white hg-bg-blue rounded-3xl p-1 w-5 h-5" style={{ strokeWidth: 3}}/></div>}
                            {r.switchId && <div><ArrowsRightLeftIcon className="w-5 h-5 text-white hg-bg-blue rounded-xl stroke-2 p-0.5 font-bold"/></div>}
                            <div className="w-2/6 ml-1 break-words flex flex-col">
                                <span className="text-sm capitalize">{r.fundShortName}</span>
                                <span className="text-xs text-gray-800 capitalize">{renderRequestType(r)}</span>
                            </div>
                            
                            {r.type === "purchase" && <div className="w-3/6 flex flex-col gap-1">
                                <span className="text-sm">{r.amount?.toLocaleString("en-my", { style: "currency", currency: r.fundClassBaseCurrency, maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span>
                                <span className="text-gray-500 text-xs">{r.units ? r.units.toLocaleString("en-my", { minimumFractionDigits: 4, maximumFractionDigits: 4 }) + " units" : "-"}</span>
                            </div>}
                            {r.type === "redeem" && r.amount > 0 && r.units && <div className="w-3/6 flex flex-col gap-1">
                                <span className="text-sm">{r.amount ? r.amount?.toLocaleString("en-my", { style: "currency", currency: r.fundClassBaseCurrency, maximumFractionDigits: 2, minimumFractionDigits: 2 }) : "-"}</span>
                                <span className="text-gray-500 text-xs">{r.units?.toLocaleString("en-my", { maximumFractionDigits: 4, minimumFractionDigits: 4 })} units</span>
                            </div>}
                            {r.type === "redeem" && !r.amount && r.requestedAmount > 0 && <div className="w-3/6 flex flex-col gap-1">
                                <span className="text-sm">{r.requestedAmount?.toLocaleString("en-my", { style: "currency", currency: r.fundClassBaseCurrency, maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span>
                                <span className="text-gray-500 text-xs">-</span>
                            </div>}
                            {r.type === "redeem" && !r.amount && !r.requestedAmount && r.units && <div className="w-3/6 flex flex-col gap-1">
                                <span className="text-sm">-</span>
                                <span className="text-gray-500 text-xs">{r.units?.toLocaleString("en-my", { maximumFractionDigits: 4, minimumFractionDigits: 4 })} units</span>
                            </div>}
                            <div>
                                {(r.status === "pending" || r.status === "processed") && <span className="text-xs ml-2 bg-orange-100 text-orange-800 border-orange-300 border p-1 rounded-md capitalize">{r.status}</span>}
                                {(r.status === "completed" || r.status === "deposited") && <span className="text-xs ml-2 bg-green-200 text-green-800 border-green-300 border p-1 rounded-md capitalize">{r.status}</span>}
                                {r.status === "cancelled" && <span className="text-xs ml-2 bg-gray-200 text-gray-800 border-gray-300 border p-1 rounded-md">Cancelled</span>}
                            </div>
                        </div>
                    </div>
                    lastRequestDate = date
                    return item
                })}
            </div>}

            {/* Portfolio experience (deposits / withdrawals / trades) */}
            {!state.accountRequestsLoading && state.accountExperience === "portfolio" && <div className="pb-5 mr-3">
                {state.accountTransactions && state.accountTransactions.length === 0 && <div className="text-center text-gray-700 border border-dashed p-6">
                    <p className="text-sm font-medium">No transactions made yet</p>
                </div>}
                {state.accountTransactions && state.accountTransactions.length > 0 && state.accountTransactions.map((r) => {
                    const date = moment(r.createdAt).format("Do MMMM")
                    const item =  <div key={r.createdAt}>
                        {date !== lastRequestDate && <span className=" bg-gray-50 mb-1 block text-sm rounded-sm text-gray-800 border p-2 font-medium capitalize">{date}</span>}
                        <div className="flex justify-between py-2 pb-3 cursor-pointer">
                            {r.category === "deposit" && <div><ArrowUpCircleIcon className="w-6 h-6 hg-text-blue"/></div>}
                            {(r.category === "buy" || r.category === "sell") && <div><ArrowsRightLeftIcon className="w-5 h-5 text-white hg-bg-blue rounded-xl stroke-2 p-0.5 font-bold"/></div>}
                            <div className="w-2/6 ml-1 break-words flex flex-col">
                                {(r.category === "buy" || r.category === "sell") && <span className="text-sm uppercase">{r.baseAsset}{r.quoteAsset}</span>}
                                {r.category === "deposit" && <span className="text-sm uppercase">{r.quoteAsset}</span>}
                                <span className="text-xs text-gray-800 capitalize">{r.category}</span>
                            </div>
                            
                            <div className="w-3/6 flex flex-col gap-1">
                                <span className="text-sm uppercase">{r.baseAsset} {r.baseAmount?.toLocaleString("en-my", { minimumFractionDigits: 4, maximumFractionDigits: 6 })}</span>
                                <span className="text-gray-500 text-xs uppercase">{r.quoteAsset} {r.quoteAmount.toLocaleString("en-my", { minimumFractionDigits: 4, maximumFractionDigits: 6 })}</span>
                            </div>
                            
                            <div>
                                <span className="text-xs ml-2 bg-green-200 text-green-800 border-green-300 border p-1 rounded-md capitalize">Completed</span>
                            </div>
                        </div>
                    </div>
                    lastRequestDate = date
                    return item
                })}
            </div>}
        </div>

        <Tabs />
    </div>
}
