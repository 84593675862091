import { useNavigate } from "react-router-dom";
import { MouseEvent, useContext } from "react";
import { Loader } from "../../component";
import { PersonaListContext } from "./context";
import { ArrowLeftCircleIcon } from "@heroicons/react/24/outline";

export default function PersonaAccountList() {
    const personaList = useContext(PersonaListContext)
    const navigate = useNavigate()

    const handleSwitch = (ev: MouseEvent<HTMLDivElement>) => {
        const {roleindex} = ev.currentTarget.dataset
        if (!roleindex) {
            navigate("/portfolio")
            return
        }
        window.localStorage.setItem("roleIndex", roleindex)
        navigate("/portfolio")
    }

    if (personaList.loading) {
        return <div className="flex justify-center items-center h-full w-full">
                <Loader classNames="w-12 h-12 text-blue-100" />
            </div>
    }

    return <div className="pb-24 md:max-w-3xl mx-auto mt-5">
            <div className="flex flex-col hg-text-blue mx-5">
                <div className="flex gap-3 mb-5">
                    <ArrowLeftCircleIcon className="my-auto w-8 h-8 text-white hg-bg-blue rounded-2xl cursor-pointer" onClick={() => navigate("/portfolio")}/>
                    <h1 className="text-xl font-medium my-auto">
                        Switching to...
                    </h1>
                </div>
                {/* Search */}
                {/* <div className="relative w-full">
                    <span className="absolute left-4 top-4 text-gray-700">
                        <MagnifyingGlassIcon className="w-5 h-5"/>
                    </span>
                    <input type="text" onChange={handleSearch} className="w-full border p-3 pl-12 rounded-full text-black" placeholder="Search accounts" />
                </div> */}
            </div>
            <div className="flex flex-col items-center mx-5">
                {personaList.clients.map((client, idx) => <div key={client.accountId} onClick={handleSwitch} data-roleindex={idx} className="w-full flex justify-between border border-blue-200 rounded-full p-3 h-16 mt-5 cursor-pointer">
                    <span className="text-sm font-medium my-auto text-blue-900 uppercase">{client.accountName}</span>
                    <span className="my-auto text-xs border border-blue-800 text-blue-900 rounded-xl p-1 w-20 text-center">{client.permission === "*" ? "Full access" : "Limited"}</span>
                </div>)}
            </div>
    </div>
}
