
import { useState, Fragment, useEffect } from "react"
import { useParams } from "react-router-dom"
import { CheckBadgeIcon, ExclamationTriangleIcon, LinkSlashIcon } from "@heroicons/react/24/outline"
import HalogenLogo from "../../asset/halogen-logo"
import { Loader } from "../../component"
import { ReviewClientRequestForSigningOutput, SignClientRequestOutput, VerifyPolicyParticipantEmailOutput, reviewClientRequestForSigning, signClientRequest } from "../../api"
import { AxiosError } from "axios"
import moment from "moment"
import Stamp from "../../asset/stamp"
import { DebounceInput } from "react-debounce-input"

interface ParticipantSignRequestState {
    loading: boolean
    reviewClientRequestForSigningOutput: ReviewClientRequestForSigningOutput
    approving: boolean
    signClientRequestOutput: SignClientRequestOutput
    signed: boolean
    error: boolean
}

export default function ParticipantSignRequest() {
    const { token } = useParams()
    const [state, setState] = useState<ParticipantSignRequestState>({
        loading: true,
        signClientRequestOutput: {
            expired: false,
        } as SignClientRequestOutput,
        reviewClientRequestForSigningOutput: {
            expired: false,
        } as ReviewClientRequestForSigningOutput,
        approving: false,
        signed: false,
        error: false,
    })

    useEffect(() => {
        if (!token) return
        reviewClientRequestForSigning({
            token,
        }).then((resp) => {
            const output = resp.data as ReviewClientRequestForSigningOutput
            setState(prev => ({
                ...prev,
                reviewClientRequestForSigningOutput: output,
            }))
        }).catch((err: AxiosError) => {
            setState(prev => ({
                ...prev,
                error: true
            }))
        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false
            }))
        })
    }, [])
    
    const handleSign = () => {
        if (!token) return
        setState(prev => ({ ...prev, approving: true }))
        signClientRequest({
            token,
        }).then((resp) => {
            const output = resp.data as SignClientRequestOutput
            setState(prev => ({
                ...prev,
                signClientRequestOutput: output,
                signed: true
            }))
        }).catch((err: AxiosError) => {
            setState(prev => ({
                ...prev,
                error: true
            }))
        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
                approving: false
            }))
        })
    }

    const renderRequestType = (type: string) => {
        switch(type) {
            case "purchase":
                return "subscription"
            case "redeem":
                return "redemption"
            default:
                return type
        }
    }

    return <div className="h-full md:max-w-3xl mr-auto ml-auto">
        <div className="mb-5 hg-bg-blue w-full py-3 flex justify-center gap-2 cursor-pointer" onClick={() => window.location.href = "https://wallet.halogen.my"}>
            <HalogenLogo color="white" className="w-6"/>
            <label className="my-auto text-white text-xl font-semibold">Wallet</label>
        </div>

        {state.loading && <div className="text-center mr-3 ml-3">
            <Loader classNames="w-12 h-12 text-blue-100" />
        </div>}
        {!state.loading && state.error && <div>
            <div className="mt-12 flex flex-col gap-3 text-center mr-3 ml-3 font-medium text-red-900">
                <ExclamationTriangleIcon className="w-16 h-16 mr-auto ml-auto text-red-500" />
                <p>Something went wrong!</p> 
                <p>Try to reload the page, if the problem persists, please do not hesitate to reach out to us at <a href="mailto:support@halogen.my" className="underline">support@halogen.my</a>.</p>
                <button onClick={() => window.location.reload()} className="mt-3 mx-auto hg-bg-blue text-white rounded-full p-3 px-12">Reload</button>
            </div>
        </div>}
        {!state.loading && !state.error && (state.reviewClientRequestForSigningOutput?.expired || (state.signed && state.signClientRequestOutput?.expired)) && <div>
            <div className="mt-12 flex flex-col gap-3 text-center mr-3 ml-3 font-medium text-amber-900">
                <LinkSlashIcon className="w-16 h-16 mr-auto ml-auto text-amber-400" />
                <p>Expired link!</p> 
                <p>Please ask the authorised person of the Halogen account to send a new link.</p>
            </div>
        </div>}
        {!state.loading && !state.error && !state.reviewClientRequestForSigningOutput?.expired && !state.signed && <div>
            <div className="flex flex-col gap-3 font-medium xs:pb-20 md:pb-0">
                {/* TODO: Show request details to sign */}
                <div className="flex flex-col justify-center items-center gap-3">
                    <Stamp />
                    <p className="text-blue-900 text-center">
                        {state.reviewClientRequestForSigningOutput?.account?.name}
                    </p>
                    <p className=" text-blue-900 text-center">
                        {state.reviewClientRequestForSigningOutput?.account?.id}
                    </p>
                </div>
                <div className="grid xs:grid-cols-2 md:grid-cols-4  gap-5 px-3 text-sm">
                    <label className="col-span-1 text-blue-950">Request type</label>
                    <label className="xs:col-span-1 md:col-span-3 capitalize">{renderRequestType(state.reviewClientRequestForSigningOutput?.request?.type)}</label>
                    
                    <label className="col-span-1 text-blue-950">Fund</label>
                    <label className="xs:col-span-1 md:col-span-3" >{state.reviewClientRequestForSigningOutput?.request?.fundName} - Class {state.reviewClientRequestForSigningOutput?.request?.fundClassLabel}</label>
                    
                    <label className="col-span-1 text-blue-950">Gross amount</label>
                    <label className="xs:col-span-1 md:col-span-3" >{state.reviewClientRequestForSigningOutput?.request?.amount?.toLocaleString("en-my", { currency: "myr", style: "currency", minimumFractionDigits: 2, maximumFractionDigits: 2 })}</label>
                    
                    <label className="col-span-1 text-blue-950">Sales charge %</label>
                    <label className="xs:col-span-1 md:col-span-3" >{state.reviewClientRequestForSigningOutput?.request?.salesChargePercentage?.toLocaleString("en-my", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%</label>
                    
                    <label className="col-span-1 text-blue-950">Net amount</label>
                    <label className="xs:col-span-1 md:col-span-3" >{state.reviewClientRequestForSigningOutput?.request?.postFeeAmount?.toLocaleString("en-my", { currency: "myr", style: "currency", minimumFractionDigits: 2, maximumFractionDigits: 2 })}</label>

                    <label className="col-span-1 text-blue-950">Requested by</label>
                    <label className="xs:col-span-1 md:col-span-3 capitalize">{renderRequestType(state.reviewClientRequestForSigningOutput?.request?.requestedBy)}</label>
                    
                    <label className="col-span-1 text-blue-950">Created on</label>
                    <label className="xs:col-span-1 md:col-span-3" >{moment(state.reviewClientRequestForSigningOutput?.request?.createdAt).format("DD MMMM YYYY")}</label>
                </div>
            </div>
            <div className="xs:fixed xs:bottom-0 xs:left-0 xs:right-0 xs:px-5 xs:bg-white xs:text-center xs:border-t-2 xs:py-5 md:relative md:border-0 md:flex md:justify-center md:w-1/2 md:mx-auto md:mt-12">
                {!state.approving ? 
                    <DebounceInput debounceTimeout={800} type="button" value={"Approve"} onChange={() => {}} onClick={handleSign} className="cursor-pointer bg-blue-700 hover:bg-blue-800 text-white p-3 rounded-full w-full" disabled={state.approving} />
                    : 
                    <Loader classNames="w-8 h-8 text-blue-100" />}
            </div>
        </div>}
        {!state.loading && !state.error && state.signed && state.signClientRequestOutput && !state.signClientRequestOutput.expired && <Fragment>
            <div className="mt-12 flex flex-col gap-3 text-center mr-3 ml-3 font-medium text-blue-900">
                <CheckBadgeIcon className="w-16 h-16 mr-auto ml-auto hg-text-blue" />
                <p>Thank you!</p> 
                {/* TODO: participant will be notified or the corporate only? */}
                <p>The request was approved, and you will be notified when the transaction is completed.</p>
            </div>

            <div className="flex flex-col gap-3 justify-center mt-12">
                <label className="text-sm text-center font-bold text-blue-900">Unlock the future of wealth with Halogen!</label>
                <button onClick={() => window.location.href = "https://wallet.halogen.my/#/create-account"} className="mx-auto hg-bg-blue text-white rounded-full p-3 px-12">Invest Now</button>
            </div>
        </Fragment>}
    </div>
}
