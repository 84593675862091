import {createContext, useEffect, useState} from 'react'
import { getPersona, getSession, listPersonaClients } from '../../api'
import { useNavigate } from 'react-router-dom'
import { Loader } from '../../component'

export interface IPersonaContext {
    email: string
    title: string
    isDelegate: boolean
    roles: PersonaRole[]

    authenticated: boolean
    authenticationExpired: boolean
    clientId: string
    accountId: string
    permission: string

    ready: boolean
    canInvest: boolean
    canRedeem: boolean
    canSwitch: boolean

    setActiveClientAccount: (clientId: string, accountId: string) => void
    setTitle: (title: string) => void
}

export interface PersonaRole {
    raw: string
    source: string
    wallet: {
        accountId: string
        clientId: string
        permission: string
    }
}

const initialState = {
    email: "",
    title: "",
    isDelegate: false,
    roles: [],

    clientId: "",
    accountId: "",
    permission: "",
    ready: false,
    authenticationExpired: false,
    authenticated: false,

    setActiveClientAccount: (clientId: string, accountId: string) => {},
    setTitle: (title: string) => {},
    canInvest: false,
    canRedeem: false,
    canSwitch: false,
}

export const PersonaContext = createContext<IPersonaContext>(initialState)

const PersonaProvider = (props: any) => {
    const navigate = useNavigate()
    const [state, setState] = useState<IPersonaContext>(initialState)

    useEffect(() => {
        getSession().then(resp => {
            if (!resp.data.clientAuthenticated) {
                navigate("/")
                setState(prev => ({...prev, ready: true, authenticated: false}))
                return
            }
            if (resp.data.applicantAuthenticated) {
                navigate("/create-account/wizard")
                setState(prev => ({...prev, ready: true, authenticated: false}))
                return
            }
            getPersona().then(resp => {
                const { title, roles, email, isDelegate } = resp.data?.persona
                if (!roles || roles.length === 0) {
                    navigate("/")
                    setState(prev => ({...prev, ready: true, authenticated: false}))
                    return
                }
                
                let roleIndex = window.localStorage.getItem("roleIndex") || 0
                if (typeof roleIndex === "string") {
                    roleIndex = parseInt(roleIndex)
                }
                if (!roleIndex || roleIndex < 0 || roleIndex >= roles.length ) {
                    roleIndex = 0
                    window.localStorage.setItem("roleIndex", "0")
                }

                const {clientId, accountId, permission} = roles[roleIndex]?.wallet
                setState({
                    email,
                    title,
                    isDelegate,
                    roles,

                    clientId,
                    accountId,
                    permission,
                    authenticated: true,
                    authenticationExpired: false,
                    ready: true,
                    
                    setActiveClientAccount,
                    setTitle: setTitle,
                    canInvest: permission === "*" || permission === "invest",
                    canRedeem: permission === "*" || permission === "redeem",
                    canSwitch: permission === "*" || permission === "switch",
                })
                if (!title) {
                    navigate("/onboarding")
                    return
                }
            }).catch(err => {
                setState(prev => ({...prev, ready: true, authenticated: false}))
                if (err.response?.status === 401) navigate("/")
            })
        }).catch(() => {
            navigate("/")
            return
        })
    }, [])
    
    const setTitle = (title: string) => {
        setState(prev => ({
            ...prev,
            title,
        }))
    }
    const setActiveClientAccount = (clientId: string, accountId: string) => {
        setState(prev => ({
            ...prev,
            clientId,
            accountId,
        }))
    }

    return <PersonaContext.Provider value={state}>
        {state.ready ? props.children : <div className="h-full flex justify-center items-center"><Loader classNames="w-12 h-12 mx-auto text-blue-100"/></div>}
    </PersonaContext.Provider>
}

interface IPersonaListContext {
    loading: boolean
    reloadToggle: boolean
    clients: {
        name: string
        clientId: string

        accountName: string
        accountId: string
        permission: string
    }[]

    reload: () => void
}

const listInitialState = {
    loading: true,
    reloadToggle: false,
    clients: [],

    reload: () => {}
}

export const PersonaListContext = createContext<IPersonaListContext>(listInitialState)

const PersonaListProvider = (props: any) => {
    const [state, setState] = useState<IPersonaListContext>(listInitialState)

    const navigate = useNavigate()

    useEffect(() => {
        listPersonaClients().then(resp => {
            setState(prev => ({
                ...prev,
                loading: false,
                clients: resp.data.clients,
                reload,
            }))
        }).catch(() => {
            navigate("/portfolio")
        })
    }, [state.reloadToggle])

    const reload = () => {
        setState(prev => ({
            ...prev,
            reloadToggle: !prev.reloadToggle,
        }))
    }
    return <PersonaListContext.Provider value={state}>
        {props.children}
    </PersonaListContext.Provider>
}

export default {
    PersonaContext,
    PersonaProvider,
    
    PersonaListContext,
    PersonaListProvider,
}
