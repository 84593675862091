import { ArrowLeftCircleIcon, BuildingLibraryIcon, CheckIcon, ChevronRightIcon, ClockIcon } from "@heroicons/react/24/outline";
import { useNavigate, useParams } from "react-router-dom";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Loader } from "../../component";
import { AccountRequest, PortfolioContext } from "./context";
import { Fund, FundListContext } from "../fund/context";
import { PersonaContext } from "../login/context";
import { ProfileContext } from "../settings/context";
import moment from "moment";
import { DebounceInput } from "react-debounce-input";
import { GetClientAccountRequest, GetClientAccountRequestPolicyOutput, RequestClientAccountPolicySignatureOutput, getClientAccountRequestPolicy, requestClientAccountPolicySignature } from "../../api";

interface PolicyState {
    output: GetClientAccountRequestPolicyOutput
    loading: boolean
    failed: boolean
    requestButtonLabel: string
    requestButtonLoading: boolean
    requestButtonDisabled: boolean
}

export default function Request() {
    const [loading, setLoading] = useState(true)
    const [fund, setFund] = useState({} as Fund)
    const [request, setRequest] = useState({} as AccountRequest)
    const [cancelLoading, setCancelLoading] = useState(false)
    const [cancelFailed, setCancelFailed] = useState(false)
    
    const navigate = useNavigate()
    const persona = useContext(PersonaContext)
    const portfolio = useContext(PortfolioContext)
    const fundList = useContext(FundListContext)
    const profile = useContext(ProfileContext)

    const [policyState, setPolicyState] = useState<PolicyState>({
        output: {} as GetClientAccountRequestPolicyOutput,
        loading: true,
        failed: false,
        requestButtonLabel: "Request signature",
        requestButtonDisabled: false,
        requestButtonLoading: false,
    })
    
    const {requestId} = useParams()
    useEffect(() => {
        if (!persona.clientId || !persona.accountId) {
            navigate("/auth/email")
            return
        }
        const request = portfolio.accountRequests?.filter(r => r.id === requestId)[0]
        if (!request) {
            portfolio.reloadAccountRequests()
            return
        }
        
        const fund = fundList.funds?.filter(f => f.id === request.fundId)[0]
        if (!fund) {
            return
        }

        if (request.consentType === "policy") {
            getClientAccountRequestPolicy({
                clientId: persona.clientId,
                accountId: persona.accountId,
                requestId: request.id,
            }).then((resp: any) => {
                const output = resp.data as GetClientAccountRequestPolicyOutput
                let label = policyState.requestButtonLabel
                let disabled = policyState.requestButtonDisabled
                const diff = moment().diff(output.lastNotifiedAt, "minutes")
                if (output.lastNotifiedAt && (30 - diff) > 0) {
                    label = "Re-request in " + (30 - diff).toString() + " minutes"
                    disabled = true
                }
                setPolicyState((prev) => ({ ...prev, output, loading: false, failed: false, requestButtonLabel: label, requestButtonDisabled: disabled }))
            }).catch(() => {
                setPolicyState((prev) => ({ ...prev, output: {} as GetClientAccountRequestPolicyOutput, loading: false, failed: true }))
            })
        }
        setLoading(false)
        setRequest(request)
        setFund(fund)
    },[ portfolio.accountRequestsLoading, fundList.funds ])

    const handleRequestSignature = () => {
        setPolicyState(prev => ({...prev, requestButtonLoading: true}))
        requestClientAccountPolicySignature({
            clientId: persona.clientId,
            accountId: persona.accountId,
            requestId: request.id,
        }).then((resp: any) => {
            const output = resp.data as RequestClientAccountPolicySignatureOutput
            portfolio.reloadAccountRequests()
            setPolicyState(prev => ({...prev, requestButtonLoading: false}))
        }).catch(() => {
            window.location.reload()
        })
    }

    const handleCancel = () => {
        if (!requestId) {
            return
        }
        setCancelLoading(true)
        portfolio.cancelRequest(requestId, () => {
            navigate("/portfolio")
        }, () => {
            setCancelLoading(false)
            setCancelFailed(true)
        })
    }

    const renderTitle = () => {
        if (request.switchId && request.type === "redeem") {
            return "switch out request"
        }
        if (request.switchId && request.type === "purchase") {
            return "switch in request"
        }
        if (request.rebateId && request.type === "purchase") {
            return "rebate"
        }
        if (request.type === "purchase") {
            return "investment request"
        }
        if (request.type === "redeem") {
            return "redemption request"
        }
        return request.type
    }

    const renderStatus = () => {
        if (request.status !== "cancelled" && request.consentType === "policy" && request.consentStatus === "pending") {
            return <span className="text-xs ml-2 bg-orange-100 text-orange-800 border-orange-300 border p-1 rounded-md capitalize">Pending approval</span>
        }
        if (request.status === "pending" || request.status === "processed") {
            return <span className="text-xs ml-2 bg-orange-100 text-orange-800 border-orange-300 border p-1 rounded-md capitalize">{!request.switchId && request.type === "purchase" && request.status === "pending" ? "Pending payment" : request.status}</span>
        }
        if (request.status === "completed" || request.status === "deposited") {
            return <span className="capitalize text-xs ml-2 bg-green-200 text-green-800 border-green-300 border p-1 rounded-md">{request.status}</span>
        }
        if (request.status === "cancelled") {
            return <span className="text-xs ml-2 bg-gray-200 text-gray-800 border-gray-300 border p-1 rounded-md">Cancelled</span>
        }
    }
    
    if (loading) {
        return <div className="h-full flex justify-center items-center">
            <Loader classNames="w-16 h-16 text-blue-100" />
        </div>
    }

    const shouldShowDuitNowPaymentMethod = ((profile.client?.type === "individual" && request?.amount <= 30000) || (profile.client?.type === "corporate" && request?.amount <= 1000000))

    return <div className="pb-24">
        <div className="relative h-32 text-center hg-bg-blue mb-10" style={{ borderBottomLeftRadius: "40%", borderBottomRightRadius: "40%"}}>
            <ArrowLeftCircleIcon className="fixed mt-2 ml-2 w-8 h-8 text-white hg-bg-blue rounded-2xl cursor-pointer" onClick={() => navigate("/portfolio")}/>
            <h1 className="pt-16 text-xl text-white pl-3 pr-6 capitalize">{renderTitle()}</h1>
        </div>

        <div className="flex flex-col 2xs:w-5/6 md:w-1/2 mr-auto ml-auto gap-3">
            <div className="flex justify-between border-b pb-3">
                <span className="font-medium text-blue-950 pr-10">Fund</span>
                <span>{fund?.name}</span>
            </div>
            <div className="flex justify-between border-b pb-3">
                <span className="font-medium text-blue-950 pr-10">Class</span>
                <span className="uppercase">{fund?.classes[parseInt(request.fundClassSequence) - 1]?.label}</span>
            </div>
            
            {request.rebateId && request.type === "purchase" && <div className="flex justify-between border-b pb-3">
                <span className="font-medium text-blue-950 pr-10">Rebate period</span>
                <div className="flex flex-col">
                    <span>{moment(request.rebateFromDate).format("DD MMM YYYY")}</span>
                    <span>{moment(request.rebateToDate).format("DD MMM YYYY")}</span>
                </div>
            </div>}

            {request.amount > 0 && <div className="flex justify-between border-b pb-3">
                <span className="font-medium text-blue-950 pr-10">Amount</span>
                <span>{request.amount?.toLocaleString("en-my", { currency: request.fundClassBaseCurrency, style: "currency", maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span>
            </div>}
            {!request.amount && request.requestedAmount && <div className="flex justify-between border-b pb-3">
                <span className="font-medium text-blue-950 pr-10">Amount</span>
                <span>{request.requestedAmount?.toLocaleString("en-my", { currency: request.fundClassBaseCurrency, style: "currency", maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span>
            </div>}
            
            {request.type === "purchase" && <div className="flex justify-between border-b pb-3">
                <span className="font-medium text-blue-950 pr-10">Sales charge %</span>
                <span>{request.salesChargePercentage ? request.salesChargePercentage?.toLocaleString("en-my", { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : "0.00"}%</span>
            </div>}
            {!request.switchId && request.type === "redeem" && <React.Fragment>
                <div className="flex justify-between border-b pb-3">
                    <span className="font-medium text-blue-950 pr-10">Redemption charge %</span>
                    <span>{request.redemptionChargePercentage ? request.redemptionChargePercentage?.toLocaleString("en-my", { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : "0.00"}%</span>
                </div>
                {!!request.settlementBankingFee && <div className="flex justify-between border-b pb-3">
                    <span className="font-medium text-blue-950 pr-10">Banking fee</span>
                    <span>{request.settlementBankingFee ? request.settlementBankingFee?.toLocaleString("en-my", { style: "currency", currency: request.fundClassBaseCurrency, maximumFractionDigits: 2, minimumFractionDigits: 2 }) : "-"}</span>
                </div>}
            </React.Fragment>}
            {request.postFeeAmount > 0 && <div className="flex justify-between border-b pb-3">
                <span className="font-medium text-blue-950 pr-10">Net amount</span>
                <span>{(request.postFeeAmount - (request.settlementBankingFee || 0))?.toLocaleString("en-my", { currency: request.fundClassBaseCurrency, style: "currency", maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span>
            </div>}
            
            {request.units > 0 && <div className="flex justify-between border-b pb-3">
                <span className="font-medium text-blue-950 pr-10">Units</span>
                <span>{request.units?.toLocaleString("en-my", { maximumFractionDigits: 4, minimumFractionDigits: 4 })}</span>
            </div>}

            <div className="flex justify-between border-b pb-3">
                <span className="font-medium text-blue-950 pr-10">Created on</span>
                <span>{moment(request.createdAt).format("DD MMMM YYYY")}</span>
            </div>

            <div className="flex justify-between">
                <span className="font-medium text-blue-950 pr-10">Status</span>
                {renderStatus()}
            </div>

        </div>

        {request.status !== "cancelled" && request.consentType === "policy" && <div>
            <div className="flex flex-col gap-10 font-medium 2xs:w-5/6 md:w-1/2 mr-auto ml-auto mt-5">
                <div className="flex flex-col gap-3">
                    {/* <h3 className="text-md font-medium text-gray-800">Approval policy:</h3> */}
                    {!policyState.loading ? 
                    <Fragment>
                        {request.consentStatus === "signed" && <label className="text-sm rounded-xl bg-green-50 text-green-700 p-3 border border-green-700">Request was signed!</label>}
                        <ul className="flex flex-col gap-3">
                            {policyState.output?.groups?.map(g => {
                                return <li key={g.label} className={`flex flex-col gap-1 text-sm`}>
                                    <div className="flex justify-between">
                                        <label className="px-3 rounded-br-full bg-blue-500 text-white">Group <span className="uppercase">{g.label}</span></label>
                                        <label className="px-3 rounded-bl-full bg-amber-500 text-white">{g.min} out of {g.max} required</label>
                                    </div>
                                    <ul className="flex flex-col gap-3 p-3">
                                        {policyState.output?.participants.filter(p => p.groupLabel === g.label)?.map(p => {
                                            if (request.consentStatus === "signed" && !p.signed) {
                                                return <Fragment key={p.email}></Fragment>
                                            }
                                            return <li key={p.email} className={`flex flex-col gap-1 text-xs ${p.signed ? "text-green-500" : "text-gray-500"}`}>
                                                <div className="flex gap-1">
                                                    <span className="my-auto">{p.signed ? <CheckIcon className="w-4 h-4"/> : <ClockIcon className="w-4 h-4"/>}</span>
                                                    <span className="uppercase">{p.name}</span>
                                                    <span>-</span>
                                                    <span>{p.email}</span>
                                                </div>
                                                {p.signedAt && <span className="ml-5 text-xs text-gray-500">Signed at {moment(p.signedAt).format("Do MMM YYYY hh:mm A")}</span>}
                                            </li>
                                        })}
                                    </ul>
                                </li>
                            })}
                        </ul>
                        {persona.canInvest && !policyState.requestButtonLoading && request.consentStatus === "pending" && <DebounceInput debounceTimeout={800} type="button" value={policyState.requestButtonLabel} onChange={() => {}} onClick={handleRequestSignature} className="text-sm cursor-pointer bg-blue-700 hover:bg-blue-800 text-white py-3 rounded-full w-full disabled:bg-blue-300" disabled={policyState.failed || policyState.loading || policyState.requestButtonDisabled || policyState.requestButtonLoading} />}
                        {policyState.requestButtonLoading && request.consentStatus === "pending" && <Loader classNames="mx-auto w-5 h-5 text-blue-100" />}
                    </Fragment> 
                    :
                    <Loader classNames="mx-auto w-8 h-8 text-blue-100"/>}
                </div>
            </div>
        </div>}
        
        {!request.switchId && request.type === "purchase" && request.status === "pending" && 
            <div className="flex flex-col gap-5 font-medium 2xs:w-5/6 md:w-1/2 mr-auto ml-auto mt-5">
                <h3 className="text-md font-medium text-gray-800">Payment methods:</h3>
                <div className="grid grid-cols-1 gap-3">
                    {shouldShowDuitNowPaymentMethod && <div className="flex flex-col col-span-1 gap-3 p-3 bg-slate-100 w-full rounded-xl cursor-pointer" onClick={() => navigate(`/portfolio/request/${requestId}/duitnow`)}>
                        <div className="flex justify-between">
                            <img src='/assets/duitnow-logo.png' width={64} height={51} />
                            <div className="flex">
                                <label className="cursor-pointer text-sm font-medium text-blue-700">Pay</label>
                                <ChevronRightIcon className="w-5 h-5 text-blue-700"/>
                            </div>
                        </div>
                        <h4 className="text-sm">Online Banking/Wallets</h4>
                        <p className="text-gray-700 text-sm">Instant transfer. Please ensure the bank limit allows for the transaction to be proceessed.</p>
                    </div>}
                    <div className="flex flex-col col-span-1 gap-3 p-3 bg-slate-100 w-full rounded-xl cursor-pointer" onClick={() => navigate(`/portfolio/request/${requestId}/banktransfer`)}>
                        <div className="flex justify-between">
                            <BuildingLibraryIcon className="w-9 h-9 text-blue-700"/>
                            <div className="flex">
                                <label className="cursor-pointer text-sm font-medium text-blue-700">Pay</label>
                                <ChevronRightIcon className="w-5 h-5 text-blue-700"/>
                            </div>
                        </div>
                        <h4 className="text-sm">Bank Transfer</h4>
                        <p className="text-gray-700 text-sm">We will match your deposit using the bank account name. Please make sure you deposit from a bank account in your name.</p>
                    </div>
                </div>

            </div>}

        {request.status === "pending" && (((request.type === "purchase" && !request.switchId) && persona.canInvest) || (request.type === "redeem" && persona.canRedeem)) && <div className="flex flex-col items-center">
            <button onClick={handleCancel} disabled={cancelLoading} className="bg-white hover:bg-red-700 hover:text-white hover:border-transparent disabled:text-red-200 mt-12 2xs:w-5/6 md:w-1/2 p-3 text-sm text-red-500 border-red-500 border rounded-full outline-0">
                {!cancelLoading ? "Cancel request" : <Loader classNames="w-6 h-6 fill-red-600 text-red-100" />}
            </button>
            {cancelFailed && <div className="mt-5 2xs:w-5/6 md:w-1/2 p-3 border border-red-700 bg-red-100 text-sm rounded-full text-center">
                An error ocurred, please try again.
            </div>}
        </div>}
        <div className="flex justify-center mt-5">
            <label className="text-sm text-blue-700 cursor-pointer" onClick={() => navigate("/settings/help")}>Contact us</label>
        </div>


    </div>
}
